import React, { Component } from "react"

export class AppFooter extends Component {

    render() {

        let ICP = ''
        if(document.domain.includes('junmapp')) {
            ICP = '粤ICP备18070854号-1'
        }else if(document.domain.includes('jmapp')) {
            ICP = '粤ICP备18070854号-2'
        }else if(document.domain.includes('ijunma')) {
            ICP = '粤ICP备18070854号-3'
        }

        return (
            <div className="footer">
                <div className="warp">
                    <div className="company">
                        <div className="name">深圳市小马哥软件科技有限公司</div>
                        <div className="address">地址：深圳市南山区高新技术产业园R3-B栋708</div>
                        <div className="phone">电话：0755-26603276</div>
                        <div className="email">邮箱：xmg@ijunma.cn</div>
                    </div>
                    <div className="wx-code">
                        <img src={require('../images/code@2x.png')} alt="公众号二维码"/>
                        <div>公众号</div>
                    </div>
                </div>
                
                <div className="record">
                    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{ICP}</a>
                </div>
            </div>
        );
    }
}