import React, { Component } from "react"
import "../style/introduce.scss" //引入当前文件scss

export class AppIntroduce extends Component {
    render() {
        return (
            <div className="intro">
                <div className="intro-header">
                    <img src={require('../images/introduce/banner@2x.png')} alt="banner"/>
                    <div className="header-mask">
                        <div className="title font-size-big">小程序+管理后台</div>
                        <div className="info">连接用户和检测站，预约审车移动化数字化管理</div>
                        <div className="btn">马上体验</div>
                    </div>
                </div>
                <div className="section section1">
                    <div className="picture-description">
                        <img src={require('../images/introduce/product1@2x.png')} alt="产品介绍1"/>
                    </div>
                    <div className="text-description">
                        <div className="title font-medium underline">审车体验</div>
                        <div className="content">智慧管控审车流程，解决收集客户信息时间长且不准确，把控审车时间节点难，登录排队，人工填表等痛点，简化审车流程。六年免检，上门接车，VIP会员提高审车体验。</div>
                    </div>
                </div>
                <div className="section section2">
                    <div className="text-description">
                        <div className="title font-size-normal font-medium underline">员工管理</div>
                        <div className="content">
                            1.摄像头拍摄识别离场车辆，系统自动向客户发送调查问卷。问卷可根据检测站需求进行员工分类，精确到个人，一目了然。<br/><br/>

                            2.系统把控流程时间点，自动生成报告，对异常时常的环节进行披露。<br/><br/>

                            3.个性化生活管理服务定制，比如员工食堂，提升管理效率同时提升员工满意度和归属感。
                        </div>
                    </div>
                    <div className="picture-description">
                        <img src={require('../images/introduce/product2@2x.png')} alt="产品介绍2"/>
                    </div>
                </div>
                <div className="section section3">
                    <div className="picture-description">
                        <img src={require('../images/introduce/product3@2x.png')} alt="产品介绍3"/>
                    </div>
                    <div className="text-description">
                        <div className="title font-size-normal font-medium underline-center">客户维护</div>
                        <div className="content">
                            1.智能化提醒客户审车和购买保险；对老客的流失进行实时跟踪和分析<br/>
                            2.设置并查看问卷调查内容。通过智能消息，人工联系等方式安抚不满意的客户，提升口碑<br/>
                            3.准确区分普通客户和中介客户，针对不同的客户进行不同的管理，有效提高检测站的服务效率和质量。<br/>
                            4.全面记录车辆审车频率，保险信息，更有利于开拓衍生业务。
                        </div>
                    </div>
                </div>
                <div className="section4">
                    <div className="content">
                        <div className="title font-size-normal font-medium underline-center">业务增长</div>
                        <div className="text font-size-small text-align-left">
                            1.根据各检测站个性化需求，不定期通过公众号和app发布软文。<br/>
                            2.具备保险管理功能，帮助检测站进行管理和营销。<br/>
                            3.建立会员体系，培养客户忠诚度，避免客户流失。<br/>
                            4.数据报表智能提醒，周报月报一目了然。
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}