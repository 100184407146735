import React, { Component } from "react"
import "./style/App.scss" //引入当前文件scss
import AppHeader from "./components/AppHeader"
import { AppFooter } from "./components/AppFooter"
import { AppHome } from "./components/AppHome"
import { AppIntroduce } from "./components/AppIntroduc"
import { AppJoin } from "./components/AppJoin"
import {HashRouter as Router, BrowserRouter, Route, Switch} from 'react-router-dom'
import './style/fade.css'
import Routes from "./components/Animation"

interface Props {
    match
}

interface State {
    routerIndex: number
}
class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            routerIndex: 0
        };
    }

    render() {
        const { routerIndex } = this.state
        return (
            <BrowserRouter>
                <AppHeader routerIndex={routerIndex}></AppHeader>
                <Routes></Routes>
                <AppFooter></AppFooter>
            </BrowserRouter>
        );
    }
}
export default App;