import React, { Component } from "react"
import "../style/join.scss" //引入当前文件scss

export class AppJoin extends Component {
    render() {
        return (
            <div className="join">
                <div className="join-header">
                    <img src={require('../images/join/banner@2x.png')} alt="banner"/>
                    <div className="header-mask">
                        <div className="font-size-big font-medium">诚邀更多检测站的加入</div>
                        <div className="btn">立即体验</div>
                    </div>
                </div>
                <div className="section1">
                    <div className="item">
                        <div className="rectangle bg-cyan">
                            <img src={require('../images/icons/chart@2x.png')} alt="折线图"/>
                        </div>
                        <div className="title">市场因素</div>
                        <div className="txt">解决新检测站渠道受限，客源稀缺。老检测站地域受限，业务增长缓慢问题。</div>
                    </div>
                    <div className="item">
                        <div className="rectangle bg-thin-cyan">
                            <img src={require('../images/icons/user@2x.png')} alt="用户"/>
                        </div>
                        <div className="title">用户因素</div>
                        <div className="txt">差异化服务于不同的用户群体，占领互联网优势高地，提升用户体验，记录并管理用户系统数据。</div>
                    </div>
                    <div className="item">
                        <div className="rectangle bg-indigo">
                            <img src={require('../images/icons/gold-icon@2x.png')} alt="成本图标"/>
                        </div>
                        <div className="title">成本因素</div>
                        <div className="txt">降低场地、人工、设备维护等各项经营成本。</div>
                    </div>
                </div>
                <div className="section2">
                    <div className="title font-medium underline-center">智慧审车前景</div>
                    <div className="content">
                        <img src={require("../images/join/section2@2x.png")} alt="前景图"/>
                        <ul className="text">
                            <li className="active">多元渠道获客</li>
                            <li>简化车主手续，优化工作人员服务流程</li>
                            <li>合理配置各项资源，改良运营管理</li>
                            <li>标准化、信息化和数据化</li>
                        </ul>
                    </div>
                </div>
                <div className="section3">
                    <div className="title font-medium underline-center">产品成效</div>
                    <div className="tip font-size-small">作为第一款聚焦于审车行业的互联网产品，小马哥审车软件在2017年推出后，深圳、广西等地的检测站纷纷投入了使用，他们都取得了可喜的成绩。</div>
                    <div className="content">
                        <div className="text">
                            <div className="item">
                                <div className="item-header">审车量同比月均增长30%</div>
                                <div className="item-content">通过小马哥审车软件，检测站做好了客户维护、提升了审车体验，在审车行业这片红海中，他们依然能取得惊人的成绩。</div>
                            </div>
                            <div className="item">
                                <div className="item-header">保险额同比月均增长38%</div>
                                <div className="item-content">通过小马哥审车软件的科学管理与精准营销，检测站提高了保险转化率，营收实现了快速增长。</div>
                            </div>
                        </div>
                        <img src={require("../images/join/section3@2x.png")} alt="成效图"/>
                    </div>
                </div>
            </div>
        );
    }
}