import React, { Component } from "react"
import { withRouter } from 'react-router-dom'

interface IProps {
    history: any
}

interface navRouter {
    title: string
    link: string
}

interface IState {
    routerIndex: number,
    navList: navRouter[]
}

class AppHeader extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            routerIndex: Number(localStorage.getItem('routerIndex')) ? Number(localStorage.getItem('routerIndex')) : 0,
            navList: [
                {title: '首页', link: '/'},
                {title: '产品介绍', link: '/introduce'},
                {title: '合伙人加盟', link: '/join'}
            ]
        }
    }

    toLink = (index: number) => {
        localStorage.setItem('routerIndex', `${index}`)
        this.setState({routerIndex: index})
        this.props.history.push(this.state.navList[index].link)
    }

    render() {
        const {routerIndex, navList} = this.state
        return (
            <div className="header">
                <div className="left">小马哥审车</div>
                <div className="nav">
                    {navList.map((nav, index) => {
                        return <span className={index === routerIndex ? 'nav-item current' : 'nav-item'} key={index} onClick={() => this.toLink(index)}>{nav.title}</span>
                    })}
                </div>
                <div className="right">
                    <div className={routerIndex === 1 ? 'btn black' : 'btn'}>联系我们</div>
                </div>
            </div>
        );
    }
}

export default withRouter(AppHeader)