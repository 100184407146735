import React, { Component } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Switch, Route, withRouter } from 'react-router-dom'
import { AppHome } from "./AppHome"
import { AppIntroduce } from "./AppIntroduc"
import { AppJoin } from "./AppJoin"

interface IProps {
    location: any
}

class Routes extends Component<IProps> {
    constructor(props: IProps){
        super(props)
    }
    render() {
        const location = this.props.location
        return (
            <div className="fill">
                {/* <TransitionGroup>
                    <CSSTransition key={location.key} timeout={1000} classNames="fade">
                        <Switch>
                            <Route exact path="/" component={AppHome} />
                            <Route exact path="/introduce" component={AppIntroduce} />
                            <Route exact path="/join" component={AppJoin} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup> */}
                <Switch>
                    <Route exact path="/" component={AppHome} />
                    <Route exact path="/introduce" component={AppIntroduce} />
                    <Route exact path="/join" component={AppJoin} />
                </Switch>
            </div>
        )
    }
}
 
export default withRouter(Routes)