import React, { Component } from "react"
import {Carousel, Input} from 'antd'
import "../style/home.scss" //引入当前文件scss
import icon1 from '../images/icons/icon1@2x.png'
import icon1Active from '../images/icons/icon1-active@2x.png'
import icon2 from '../images/icons/icon2@2x.png'
import icon2Active from '../images/icons/icon2-active@2x.png'
import icon3 from '../images/icons/icon3@2x.png'
import icon3Active from '../images/icons/icon3-active@2x.png'
import flow1 from '../images/icons/flow-icon1@2x.png'
import flow1Active from '../images/icons/flow-icon1-active@2x.png'
import adminIcon from '../images/icons/admin-icon@2x.png'
import adminIconActive from '../images/icons/admin-icon-active@2x.png'
import userIcon from '../images/icons/user-icon@2x.png'
import userIconActive from '../images/icons/user-icon-active@2x.png'
import stationIcon from '../images/icons/station-icon@2x.png'
import stationIconActive from '../images/icons/station-icon-active@2x.png'

interface Props {}

interface ActiveItem {
    icon: string
    iconActive: string
    text: string | string[]
}

interface State {
    dots: string[]
    index: number
    phone: number | string
    milepost: string[]
    activeIndex: number
    activeList: ActiveItem[]
    flowIndex: number
    flowList: ActiveItem[]
}
export class AppHome extends Component<Props, State> {
    slider: any = undefined
    constructor(props: Props) {
        super(props)
        this.state = {
            dots: ['', '', ''],
            index: 0,
            phone: '',
            milepost: ['第一周检车量达到600', '检测站受益增长300%', '同比相同检测站效率最高'],
            activeIndex: 0,
            activeList: [
                {icon: icon1, iconActive: icon1Active, text: ['功能齐全', '操作简便']},
                {icon: icon2, iconActive: icon2Active, text: ['多年耕耘', '行业领先']},
                {icon: icon3, iconActive: icon3Active, text: ['专业团队', '维护无忧']}],
            flowIndex: 0,
            flowList: [
                {icon: flow1, iconActive: flow1Active, text: '审车体验'},
                {icon: adminIcon, iconActive: adminIconActive, text: '员工管理'},
                {icon: userIcon, iconActive: userIconActive, text: '客户维护'},
                {icon: stationIcon, iconActive: stationIconActive, text: '业务增长'},
            ]
        };
    }
    
    goTop = (index: number) => {
        this.slider && this.slider.innerSlider.slickGoTo(index)
        this.setState({index: index})
    }

    handleChange = (event) => {
        this.setState({phone: event.target.value});
    }

    hoverStyle = (index: number) => {
        this.setState({activeIndex: index});
    }

    flowStyle = (index: number) => {
        this.setState({flowIndex: index})
    }

    render() {
        const { index, dots, phone, milepost, activeIndex, activeList, flowIndex, flowList } = this.state
        return (
            <div className="app">
                <div className="app-header">
                    <Carousel dots={false} afterChange={this.goTop} ref={el => (this.slider = el)}>
                        <div className="banner" key={0}>
                            <img className="banner-image" src={require('../images/banner/banner1@2x.png')} alt="banner1"/>
                            <div className="mask-content">
                                <div className="mask-title">
                                    <div className="word-art font-size-normal">智慧审车平台</div>
                                    <div className="word-art font-size-big">引领全新便捷审车体验</div>
                                </div>
                                <div className="mask-from">
                                    <div className="title">申请免费试用</div>
                                    <Input placeholder="请输入手机号" value={phone} onChange={this.handleChange} />
                                    <div className="post-btn">立即提交</div>
                                </div>
                            </div>
                        </div>
                        <div className="banner" key={1}>
                            <img className="banner-image" src={require('../images/banner/banner2@2x.png')} alt="banner2"/>
                            <div className="mask-content second">
                                <div className="mask-title">
                                    <div className="word-art font-size-big">车辆年检新体验</div>
                                    <div className="word-art font-size-big">预约过程不出门</div>
                                    <div className="wx-art">扫一扫微信体验</div>
                                    {/* <div><img className="banner-image" src={require('./images/banner/banner3@2x.png')} alt=""/></div> */}
                                </div>
                                <div className="mask-image"><img src={require('../images/banner/phone@2x.png')} alt="phone"/></div>
                            </div>
                        </div>
                        <div className="banner" key={2}>
                            <img className="banner-image" src={require('../images/banner/banner3@2x.png')} alt="banner3"/>
                            <div className="mask-content">
                                <div className="mask-title">
                                    <div className="word-art font-size-big">诚邀各大检测站携手打造数字化未来</div>
                                    <div className="word-art font-size-big">构建智慧审车新篇章</div>
                                    <div className="join-btn">立即加入</div>
                                    {/* <div><img className="banner-image" src={require('./images/banner/banner3@2x.png')} alt=""/></div> */}
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    {/* <div className="dots">
                        { dots.map((value, i) => {
                            return <span key={i} onClick={() => this.goTop(i)} className={index === i ? 'dot active' : 'dot'}></span>
                        }) }
                    </div> */}
                </div>
                <div className="notice">
                    <div className="notice-content">
                        <img className="notice-icon" src={require('../images/icons/notice@2x.png')} alt="notice-icon"></img>
                        <span>动态通知：2019/08/26 小程序新增检测站服务人员评价功能</span>
                    </div>
                    <div>查看更多 ></div>
                </div>
                <div className="section">
                    <div className="section-title">
                        <span className="postfix">产品优势</span>
                    </div>
                    <div className="section-content">
                        {activeList.map((item, index) => 
                            <div key={index} className={activeIndex === index ? 'section-item active' : 'section-item'} onMouseOver={()=>this.hoverStyle(index)}>
                                <img className="item-icon" src={activeIndex === index ? item.iconActive : item.icon} alt="icon"/>
                                <div className="font-medium">{item.text[0]}<br/>{item.text[1]}</div>
                            </div>
                        )}
                        {/* <div className="section-item" onMouseOver={this.addStyle}>
                            <img className="item-icon" src={require('../images/icons/icon1@2x.png')} alt="icon1"/>
                            <div className="font-medium">功能齐全<br/>操作简便</div>
                        </div>
                        <div className="section-item">
                            <img className="item-icon" src={require('../images/icons/icon2@2x.png')} alt="icon2"/>
                            <div className="font-medium">多年耕耘<br/>行业领先</div>
                        </div>
                        <div className="section-item">
                            <img className="item-icon" src={require('../images/icons/icon3@2x.png')} alt="icon3"/>
                            <div className="font-medium">专业团队<br/>维护无忧</div>
                        </div> */}
                    </div>
                    <div className="bg-car"><img src={require('../images/bg-car@2x.png')} alt="bg-car"/></div>
                </div>
                <div className="aside">
                    <div className="aside-item aside-left">
                        <div className="aside-title">多端服务 智慧审车</div>
                        <div className="aside-content">一站式服务全面满足车主审车需求提升检测站运营效率和服务质量</div>
                        <div className="aside-tips">专属小程序和公众号连接客户，便于线上审车业务。短信提醒，公众号推文，不定期发布相关信息。电脑端管理后台实时管理员工及客户数据。</div>
                    </div>
                    <div className="aside-item aside-right">
                        <div className="video">
                            <video src="http://t.ijunma.cn/assets/xmg.mp4" controls={true}></video>
                        </div>
                    </div>
                </div>
                <div className="section section2">
                    <div className="section-title">
                        <span className="postfix">解决方案</span>
                    </div>
                    <div className="section-scheme">
                        <div className="mockup"><img src={require('../images/mockup@2x.png')} alt="mockup"/></div>
                        <div className="flow">
                            <div className="flow-items">
                                {flowList.map((item, index) => 
                                    <div key={index} className={flowIndex === index ? 'item active' : 'item'} onMouseOver={()=>this.flowStyle(index)}>
                                        <i className={flowIndex === index ? 'prefix' : ''}></i>
                                        <img className="flow-icon" src={flowIndex === index ? item.iconActive : item.icon} alt="flow-icon"></img>
                                        {item.text}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="client">
                    <div className="example">
                        <div className="font-medium font-size-normal">加盟客户实例</div>
                        <div className="station">
                            <div className="station-name font-medium font-color-black">合浦某检测站加盟</div>
                            <div className="font-size-small font-color-gray">广西省北海市合浦检测站于2019年1月加盟入小马哥审车，期间成效明显。带动周边商业。</div>
                        </div>
                        { milepost.map(item => 
                            <div className="item" key={item}>
                                <span className="arrow-icon"><img src={require("../images/icons/arrow-right@2x.png")} alt="右箭头"/></span>
                                <span className="font-medium font-size-small font-color-gray">{item}</span>
                            </div>) }
                    </div>
                    <div className="review font-medium">
                        <div className="font-medium font-size-normal">客户评价</div>
                        <div className="detail font-size-small">
                            <img src={require("../images/client@2x.png")} alt=""/>
                            <div className="info">自从入驻小马哥审车以来检测站来往车辆变多，而且减轻了服务人员的劳动</div>
                            <div className="info no-border">加强了员工的积极性，大家现在都干劲十足</div>
                            <img className="semi top" src={require("../images/icons/semi-top@2x.png")} alt="分号上"/>
                            <img className="semi bottom" src={require("../images/icons/semi-bottom@2x.png")} alt="分号下"/>
                        </div>
                    </div>
                </div> */}
                <div className="client">
                    <div className="example">
                        <div className="title">成功客户案例</div>
                        <div className="text">广西红骏马检测站成立于2013年，是一家在当地具有知名度的大型检测站。近年来，随着同行竞争的加剧及当地汽车保有量增速的放缓，该检测站的发展遇到了瓶颈期。该检测站的负责人审时度势，顺应“互联网+”的大潮流，于2018年7月引入了“智慧审车”平台，在“智慧审车”的帮助下，红骏马检测站大幅地提高了运营效率、审车量，还开拓了审车的衍生业务。</div>
                    </div>
                    <div className="example-detail">
                        <div className="row">
                            <div className="meta head">痛点</div>
                            <div className="meta head">解决方案</div>
                            <div className="meta head">效果</div>
                        </div>
                        <div className="row">
                            <div className="meta">检测站营运效率低下、流程混乱。审车高峰期时，员工应接不暇，造成车主排队过久，怨声载道。</div>
                            <div className="meta">预约审车/打印表格/服务评价——提前手机车主资料、自动打印表格、车主错开高峰期。</div>
                            <div className="meta">获取车主信息，利于数据分析及二次营销；老客流失率降到个数</div>
                        </div>
                        <div className="row">
                            <div className="meta">无法精准、全面地提醒老客年审到期，造成老客流失严重。</div>
                            <div className="meta">客户管理/年审提醒——获得车主信息、精准地通过短信/公众号信息提醒老客年审到期</div>
                            <div className="meta">平均每位车主审车时间减少<span>35%</span>；车主好评率达<span>98%</span>；2019年上半年审车量同比增长<span>26%</span></div>
                        </div>
                        <div className="row">
                            <div className="meta">业务单一，营收过于依赖审车量。</div>
                            <div className="meta">VIP业务/保险业务/上门接车——开拓审车衍生业务</div>
                            <div className="meta">2019年上半年新增<span>4800</span>个VIP车主；保险转化率达<span>78%</span>；上门接车便利车主，也为检测站带来额外营收</div>
                        </div>
                    </div>
                    <div className="bg-mount"><img src={require('../images/bg-mount@2x.png')} alt="bg-mount"/></div>
                </div>
                <div className="partners">
                    <div className="font-size-normal font-color-black font-medium">战略合作伙伴</div>
                    <div className="list">
                        <div className="item">安保检测站</div>
                        <div className="item">合浦红骏马检测站</div>
                        <div className="item">防城丰汇机动车检测站</div>
                        <div className="item margin-0">华星检测站</div>                    
                    </div>
                    <div className="list margin-0">
                        <div className="item">都兴检测站</div>
                        <div className="item special">东盛检测站</div>
                        <div className="item">……</div>    
                    </div>
                </div>
            </div>
        );
    }
}